<template>
<div class="doc-container">
	<v-app>
		<div class="d-flex">
			<div class="flex-grow-1">
				<CCard class="p-0">
					<CCardHeader>
						<div class="d-flex">
							<div class="flex-grow-1">
								<h5>Surat Surel</h5>
							</div>
							<div class="flex-grow-0 ml-10"></div>
						</div>
					</CCardHeader>
					<CCardBody>
						<v-data-table
							:loading="loading"
							loading-text="Memuat Data..."
							:headers="modelTable.headers"
							:options.sync="optionsTable"
							:server-items-length="modelTable.totalData"
							:multi-sort="false"
							:items="modelTable.dataList"
							:single-expand="true"
							:expanded.sync="modelTable.expandedTable"
							item-key="filename"
							show-expand
							:footer-props="modelTable.footerProps"
							class="elevation-1">
								<template v-slot:[`item.actions`]="{ item }">
									<v-icon small class="mr-2 text-primary" @click="showIncomingDocForm(item)">
										mdi-share
									</v-icon>
								</template>
								<template v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length" class="p-4">
										<div class="mb-20">
											Surat {{ item.filename }}
										</div>
										<div class="mb-20 w-100">
											<div class="content-center p-2">
												<div>
													<CButton class="btn-sm mb-20 mt-20" color="info" @click="previewItem(item)">
														<font-awesome-icon v-c-tooltip="'Unduh surat'" icon="download" class="mr-1"/> Unduh
													</CButton><br/>
													Klik tombol diatas untuk mengunduh surat.
												</div>
											</div>
										</div>
									</td>
								</template>
								<template v-slot:no-data>
									<span>Data Tidak Ditemukan</span>
								</template>
						</v-data-table>
					</CCardBody>
				</CCard>
			</div>
		</div>

		<!-- Incoming Document Popup Modal -->
		<incoming-document-form
			v-bind:popup-modal-prop="incomingDocForm.modal"
			v-bind:email-doc-prop="incomingDocForm.emailDoc"
			v-bind:category-options-prop="incomingDocForm.categoryOptions"
			v-bind:tag-options-prop="incomingDocForm.receiverOptions"
			v-on:close-popup-modal="closeIncomingDocForm">
		</incoming-document-form>
	</v-app>
</div>
</template>

<script>
import { mapState } from 'vuex'
import 'vue-multiselect/dist/vue-multiselect.min.css';
import IncomingDocumentForm from '@/components/document/IncomingDocument.vue'
export default {
	names: 'Mailbox',
    components: {
        'incoming-document-form': IncomingDocumentForm
	},
	data () {
		const modelTable = {
			headers: [
				{ text: '#', value: 'data-table-expand', sortable: false, align: 'center' },
				{ text: 'Subjek', value: 'subject', sortable: true },
				{ text: 'Nama Surat', value: 'filename', sortable: true },
				{ text: 'Sumber', value: 'source', sortable: true },
				{ text: 'Tanggal', value: 'sentAt', sortable: true, align: 'center' },
				{ text: 'Tersedia', value: 'availability', sortable: true, align: 'center' },
				{ text: 'Aksi', value: 'actions', sortable: false, align: 'center' }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': 'Data Ditampilkan'
			},
			totalData: 0,
			dataList: [],
			expandedTable: []
		}
		const incomingDocForm = {
			modal: {
				isShow: false
			},
			emailDoc: null,
			categoryOptions: [],
			receiverOptions: []
		}

		return {
			loading: true,
			modelTable,
			incomingDocForm,
			optionsTable: {}
		}
	},
	computed: {
		...mapState('auth', {
			loggedUserId: 'user_id',
			loggedUserPosition: 'position'
		}),
	},
	watch: {
		optionsTable: {
			deep: true,
			handler () {
				this.getDataList()
			}			
		}
    },
	mounted () {
		this.getDataList()
		this.getCategoryOptions()
		this.getReceiverOptions()
	},
	methods: {
		getCategoryOptions () {
			this.$store.dispatch(
				{
					type: 'document/getCategory',
					pagination: {
						limit: 100,
						offset: 0
					},
					order: {
						orderType: 'asc'
					}
				}
			).then( res => {
				let items = []
				res.result.forEach((item) => {
					let rowData = {
						id: item.category_id,
						name: item.category_name,
						label: item.category_name
                    }
					items.push(rowData)
				})
				
                this.incomingDocForm.categoryOptions = items
			})
		},
		getReceiverOptions () {
			this.$store.dispatch({
					type: 'user/getUser',
					pagination: {
						limit: 200,
						offset: 0
					},
					order: {
                        orderBy: 'fullname',
                        orderType: 'asc'
                    },
                    filter: {
                        isActive: 'true'
                    }
			}).then( res => {
				let items = []
				for (let i = 0; i < res.result.length; i++) {
					const item = res.result[i];
					let position = (item.position != null) ? item.position : ''
					let positionExisted = items.some(el => el.position === position)
					if ((item.position != this.loggedUserPosition) && !positionExisted) {
						let label = position.trim()
						let rowData = {
							label: label,
							id: item.user_id,
							userId: item.user_id,
							fullname: item.fullname,
							email: item.email,
							rank: item.rank,
							position: item.position,
							command: null
						}
						items.push(rowData)
					}
				}
				
				this.incomingDocForm.receiverOptions = items
			})
        },
		getDataList () {
			this.loading = true
			return new Promise((resolve, reject) => {
				const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable

				this.$store.dispatch(
					{
						type: 'document/getEmailDocument',
						pagination: {
							limit: itemsPerPage,
							offset: (page-1)*itemsPerPage
						}
					}
				).then( res => {
					let items = []
					let total = 0

					if (res) {
						res.result.forEach((item) => {
							let rowData = {
								docFileId: item.doc_file_id,
								subject: item.subject_email,
								source: item.source,
								filename: item.filename,
								sentAt: this.$moment(item.collect_at).format("Y-MM-DD, HH:mm"),
								availability: (item.is_post === false) ? 'Ya' : 'Tidak'
							}
							items.push(rowData)
						})
						total = res.total_count
					}
					
					this.modelTable.dataList = items
					this.modelTable.totalData = total
				}).catch( err => {
					console.log('Failed to fetch mail data')
					console.log(err)
				}).finally( () => {
					this.loading = false
				})
			})
		},
		previewItem (item) {
			this.$store.dispatch({
				type: 'document/fetchMailbox',
				formInput: {
					docFileId: item.docFileId,
					outputType: 'file'
				}
			}).then( res => {
				if(res) {
					let physicalFile = new File(
						[res.file], 
						item.filename, 
						{ 
							type: res.file.type, 
							lastModified: Date.now() 
						}
					)

					let fileURL = window.URL.createObjectURL(physicalFile)
					let link = document.createElement("a")
						link.href = fileURL
						link.download = res.filename
						link.click()

					setTimeout(function() {
						window.URL.revokeObjectURL(fileURL)
					}, 100)
				} else {
					this.$toasted.info('Surat tidak ditemukan!').goAway(3000)
				}
			}).catch( err => {
				console.log(err)
				this.$toasted.error('Gagal memuat data, silahkan coba kembali!').goAway(3000)
			})
		},
		showIncomingDocForm(emailDocProp) {
			if (emailDocProp != null) {
				if (emailDocProp.availability == 'Ya') {
					this.incomingDocForm.emailDoc = emailDocProp
					this.incomingDocForm.modal.isShow = true
				}
			}
		},
		closeIncomingDocForm() {
			this.incomingDocForm.modal.isShow = false
			this.incomingDocForm.emailDoc = null
			this.getDataList()
		}
	}
}
</script>

<style scoped lang="scss">
#app {
	background-color: #f5f5f5;
}
</style>